@font-face {
  font-family: jost;
  src: url(./assets/Jost-VariableFont_wght.ttf);
}

.App {
  text-align: center;
  font-family: jost;
  background-image: url(./assets/linear-toptobot.png), linear-gradient(to bottom, #58BC2B, #ACFF7B);
  background-position: center bottom, center top;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100vw;
  overflow: hidden;
  color: #fff;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 11.806vw;
  margin: 0rem 0 0 0;
  text-shadow:
    -2px -2px 0 #58BC2B,
    2px -2px 0 #58BC2B,
    -2px 2px 0 #58BC2B,
    2px 2px 0 #58BC2B,
    0 1rem 0 #ACFF7B;
    animation: float 6s ease-in-out infinite;

}
@keyframes float {
	0% {
		text-shadow: 0 0.26vw 0.781vw 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		text-shadow: 0 1.302vw 0.781vw 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		text-shadow: 0 0.26vw 0.781vw 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}


.headerimgsContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.btn {
  width: 30%;
  aspect-ratio: 3;
  background-color: #fff;
  border-radius: 48px;
  border: #fff 5px solid;
  box-shadow: 4px 4px 4px #fff;
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 300ms ease-in-out;
}

.btn:hover {
  background-size: 35%;
  cursor: pointer;
}

.xbtn:hover {
  background-color: #222;
}

.telegrambtn:hover {
  background-color: #1f8ec6;
}

.xbtn {
  background-image: url(./assets/Xlogo.png);
  background-color: #000;
}

.telegrambtn {
  background-image: url(./assets/Telegramlogo.png);
  background-color: #24A1DE;
}
.InfoFooter{
  background-image: url(./assets/FooterGraphics.png),linear-gradient(to bottom, #234614,#448826);
  background-position: right bottom,bottom center;
  background-repeat: no-repeat;
  background-size: 100%,100%;
  width: 100%;
  height: max-content;
  padding-bottom: 50vw;
  border-radius: 15.625vw 15.625vw 0 0;
  box-shadow: 0 -1rem 0 #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partnerlogo{
  width: 31%;
  aspect-ratio: 4;
}
.footerdataContainer{
  font-size: 4.167vw;
  font-weight: 300;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

}
p{margin: 0;}
.seperator{
  width: 62.5%;
  height: 0.5rem;
  background-color: #ACFF7B;
  border-radius: 100%;
  margin: 1rem 0;
}
.container{
  background-color: #fff;
  border-radius: 110px;
  height: 8vw;
  border: 0.313vw solid #ACFF7B;
  box-shadow: 0.313vw 0.313vw 0 #ACFF7B;
  padding: 0 20px;
}
.availableon{
  width: 31%;
  aspect-ratio: 4;
  background-image: url(./assets/Raydium.jpg);
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.CA{
  display: flex;
  flex-direction: row;
align-items: center;}
.CAText{
  color: #000;
  font-size: 3.5vw;
}
.copyButton{
  height: 50%;
  background-color: #6AD3F3;
  font-weight: 700;
  font-size: 2vw;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 10vh;
  padding: 0.833vw;
  margin-left: 20px;
  transition: all 500ms ease-in-out;
}

.copyButton:hover{
  cursor: pointer;
  background-color: #448a9f;
  color: rgba(0, 0, 0, 1);

}